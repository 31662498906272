import firebase from "firebase";
import firestore from "./firestore";
export default async function Updatemenudata(object,id) {
  try{
   
    const userRef = await firebase.firestore().collection('menus').doc(id).update(object);
   var data = await userRef.docs.map(doc => doc.data());
    return data;
  }
  catch(e){
    return "error"
  }
}