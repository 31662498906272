import React from "react";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,

    FormGroup,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import axios from 'axios';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { Editor } from "react-draft-wysiwyg";
import {
    EditorState,
    convertToRaw,
    convertFromHTML,
    ContentState
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import firebase from "firebase";
import Geocode from "react-geocode";
const db = firebase.firestore();
class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            restaurant_email: '',
            restaurant_owner: '',
            restaurant_url: '',
            city: "",
            status: "",
            address: "",
            aboutus: "",
            timings: "",
            phone_no: "",
            images: [],
            Latitude: "",
            Longitude: "",
            fileObj: [],
            files: [],
            dataid: "",
            url: "",
            gmapsLoaded: false, dataid: ""
        }
    }
    componentDidMount = async () => {
        var response2 = await firebase.firestore().collection("restaurant").where('restaurant_id', '==', localStorage.getItem("restid")).get();
        const data = await response2.docs.map(doc => doc.data());
        const data2 = await response2.docs.map(doc => doc.id);
        console.log('data', data);
        this.setState({
            name: data[0].name,
            restaurant_email: data[0].restaurant_email,
            restaurant_url: data[0].restaurant_url,
            city: data[0].city,
            address: data[0].address,
            phone_no: data[0].phone_no,
            timings: data[0].timings,
            title: data[0].title,
            aboutus: data[0].about_us,
            Latitude: data[0].lat,
            Longitude: data[0].lng,
            dataid: data2[0],
            images: data[0].featured_images,
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(data[0].description ? data[0].description : "")
                )
            ),

        });

        window.initMap = this.initMap
        const gmapScriptEl = document.createElement(`script`)
        gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk&libraries=places&callback=initMap`;
        // https://maps.googleapis.com/maps/api/js?key=SECRET_EATING&libraries=places&callback=initMap
        document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)
    }

    initMap = () => {
        this.setState({
            gmapsLoaded: true,
        })
    }

    aboutush(e) {
        this.setState({ aboutus: e.target.value });
    }

    deleteImage(e) {
        this.state.images.splice(e.currentTarget.id, 1);
        this.setState({ images: this.state.images });
    }


    changecity = (event) => {
        this.setState({ city: event.target.value });
    }

    changerestaurant_url = (event) => {
        this.setState({ restaurant_url: event.target.value });
    }
    changetitle(e) {
        this.setState({ title: e.target.value });
    }

    timingf(e) {
        this.setState({ timings: e.target.value });
    }

    changephone_no = (event) => {
        this.setState({ phone_no: event.target.value });
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
        this.setState({ address });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {

                this.setState({ Latitude: latLng.lat, Longitude: latLng.lng, permanent_address: latLng, address: address });
                // this.fetchAddress(latLng.lat,latLng.lng);
            })
            .catch(error => console.error('Error', error));
    };

    // fetchAddress(lati,lngi)
    //      {

    //        Geocode.setApiKey("AIzaSyDM4LDXtUX7rM-FHenlmmXZ7jbfbczfqWk");
    //        Geocode.setLanguage("en");
    //        Geocode.setRegion("es");
    //        Geocode.setLocationType("ROOFTOP");
    //        Geocode.enableDebug();
    //        Geocode.fromLatLng(lati,lngi).then(
    //           (response) => {
    //             const address = response.results[0].formatted_address;
    //             let city, state, country;
    //             for (let i = 0; i < response.results[0].address_components.length; i++) {
    //               for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
    //                 switch (response.results[0].address_components[i].types[j]) {
    //                   case "locality":
    //                     city = response.results[0].address_components[i].long_name;
    //                     break;
    //                   case "administrative_area_level_1":
    //                     state = response.results[0].address_components[i].long_name;
    //                     break;
    //                   case "country":
    //                     country = response.results[0].address_components[i].long_name;
    //                     break;
    //                 }
    //               }
    //             }
    //             this.setState({address: city+", "+state+", "+country,city:city});
    //           },
    //           (error) => {
    //             console.error(error);
    //           }
    //         );


    //           // this.getDocumentNearBy(lati, lngi, 50);

    //      }

    doCreatePost() {
        if (this.state.name == "" || this.state.city == "" || this.state.address == "" || this.state.timings == "" || this.state.timings == undefined
            || this.state.phone_no == "" || this.state.description == "" || this.state.title == "" || this.state.aboutus == "" || this.state.Latitude == "" || this.state.Longitude == "") {
            alert("Please fill all fields");
        }
        else {
            if (this.state.images.length > 0) {
                // var object = {
                //     name: this.state.name,
                //     title: this.state.title,
                //     restaurant_url: this.state.restaurant_url,
                //     //  city: this.state.city,
                //     address: this.state.address,
                //     phone_no: this.state.phone_no,
                //     about_us: this.state.aboutus,
                //     Latitude: this.state.Latitude,
                //     timings: this.state.timings,
                //     featured_images: this.state.images,
                //     Longitude: this.state.Longitude
                // };
                // console.log("prima di update")
                // console.log(object);
                // db.collection('restaurant').doc(this.state.dataid).update(object);  no chiamata da frontendP
                var post_images = this.state.images;
                var body = {
                    name: this.state.name,
                    title: this.state.title,
                    restaurant_url: this.state.restaurant_url,
                    address: this.state.address,
                    phone_no: this.state.phone_no,
                    about_us: this.state.aboutus,
                    lat: this.state.Latitude,
                    long: this.state.Longitude,
                    featured_images: post_images,
                    timings: this.state.timings,
                    id: this.state.dataid,
                    banner_image: post_images[0],
                    description: this.state.description,
                    restaurant_email: this.state.restaurant_email,
                    restaurant_id: this.state.dataid,
                    resturent_id: this.state.dataid
                };
                axios.post('https://foodapi.ucooking.it/updateRestu', body, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                })
                    .then((res) => {
                        window.alert(res.message);
                        window.location.reload();
                    });
            }
            else {
                alert("please select minimum 1 images");
            }
        }
    }
    onEditorStateChange = (editorState) => {
        this.setState({ editorState }, () => {
            var currentContent = editorState.getCurrentContent();
            var contentRaw = convertToRaw(currentContent);
            const value = currentContent.hasText() ? draftToHtml(contentRaw) : "";
            this.setState({ description: value });
        });
    };

    handleInput = e => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
            if (streetaddress == "data:image") {
                this.handleUpload(reader.result)

            }
        };
    }

    handleUpload = (data) => {
        var body = { image: data };
        axios.post('https://foodapi.ucooking.it/imageurl_ucooking', body, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then((res) => {
            this.state.images.push(res.data.image);
            this.setState({ images: this.state.images });
        })
    }
    render() {
        return (
            <>
                <div>

                    <Row>
                        <Col sm={12} p-4>
                            <div>
                                {this.props.message && (
                                    <Alert className="alert-sm" bsstyle="info">
                                        {this.props.message}
                                    </Alert>
                                )}
                                <FormGroup>
                                    <label for="input-title">Name</label>
                                    <Form.Control
                                        id="input-title"
                                        type="text"
                                        placeholder="Restaurant Name"
                                        value={this.state.name}
                                        readable
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label for="input-title">Title</label>
                                    <Form.Control
                                        id="input-title"
                                        type="text"
                                        placeholder="Title"
                                        value={this.state.title}
                                        required
                                        onChange={this.changetitle.bind(this)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label for="input-datefrom">Email</label>
                                    <Form.Control
                                        id="input-datefrom"
                                        type="email"
                                        placeholder="Email"
                                        value={this.state.restaurant_email}
                                        readable
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label for="input-phone_no">Phone No</label>
                                    <Form.Control
                                        id="input-phone_no"
                                        type="number"
                                        placeholder="Phone No"
                                        value={this.state.phone_no}
                                        onChange={this.changephone_no.bind(this)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label for="input-restaurant_url">Restaurant Url</label>
                                    <Form.Control
                                        id="input-restaurant_url"
                                        type="text"
                                        placeholder="Restaurant Url"
                                        value={this.state.restaurant_url}
                                        onChange={this.changerestaurant_url.bind(this)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label for="input-timing">Timing</label>
                                    <Form.Control
                                        id="input-timing"
                                        type="text"
                                        placeholder="Timing"
                                        value={this.state.timings}
                                        onChange={this.timingf.bind(this)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label for="input-autocomplete">Address</label>
                                    {this.state.gmapsLoaded && (<PlacesAutocomplete
                                        value={this.state.address}
                                        onChange={this.handleChange.bind(this)}
                                        onSelect={this.handleSelect.bind(this)}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: 'Search Places ...',
                                                        className: 'location-search-input form-control',
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'suggestion-item--active'
                                                            : 'suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>


                                        )}
                                    </PlacesAutocomplete>)}

                                </FormGroup>
                                <FormGroup>
                                    <label for="input-opening_status">Description</label>
                                    <Editor
                                        editorState={this.state.editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={this.onEditorStateChange.bind(this)}
                                    />
                                </FormGroup>


                                <FormGroup>
                                    <label for="input-phone_no">About us</label>
                                    <Form.Control
                                        id="input-abpit"
                                        type="text"
                                        placeholder="About us"
                                        value={this.state.aboutus}
                                        onChange={this.aboutush.bind(this)}

                                    />
                                </FormGroup>
                                <FormGroup sm={6} >
                                    <label for="featured_image">Featured Images</label>
                                    <input type="file" className="form-control" onChange={this.handleInput.bind(this)} />
                                </FormGroup>
                                {/* <FormGroup sm={6}>
                    <Button  color="success" type="button" >
                     Upload
                    </Button>
                  </FormGroup> */}

                                <div class="row">
                                    <div className="form-group multi-preview">
                                        {(this.state.images || []).map((url, i) => {
                                            return (

                                                <div class="col-sm-4 col-md-3">
                                                    <div className="multiimages">
                                                        <i class="fa fa-times-circle" id={i} onClick={this.deleteImage.bind(this)}></i>
                                                        <img src={url.indexOf("https") == 0 ? url : this.state.url + url} alt="Snow" defaultstyle="width:100%" className="w-100" />
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>
                                </div>



                                <div className="d-flex justify-content-end">

                                    <Button color="danger" type="button" onClick={this.doCreatePost.bind(this)} >
                                        {this.props.loadings ? 'Updateing...' : 'Update Restaurant'}
                                    </Button>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default Users;
