import React, { Component } from 'react';
import { Link  } from "react-router-dom";
import $ from 'jquery';
import Loginapi from '../API/Loginapi.js';
class Login extends  React.Component {
    constructor(props) {
       super(props);
       this.state={loginerror:"",loader:false,email:"",pass:"",firstname:"",logemail:"",logpass:"",lastname:"",passtype2:"password",setemail:"",setpassword:"",number:"",passtype:"password"
      }
      }


      getauthdata= async ()=>{
        if(this.state.email=="")
        {
           this.setState({logemail:"Please enter email address"},()=>
           {
              $('.logemail').addClass('commonerror');
           })   
        }
        if(this.state.pass=="")
        {
           this.setState({logpass:"Please enter password "},()=>
           {
           $('.logpass').addClass('commonerror');
           })
        }
        if(this.state.pass.length<5)
        {
           this.setState({logpass:"Please enter password minmum 6 digit"},()=>
           {
           $('.logpass').addClass('commonerror');
           })
        }
        if(this.state.logemail=="" && this.state.logpass=="" && this.state.pass.length>5 && this.state.email!=="" )
        {
            this.setState({loader:true});
        try 
        {
           const notified = await Loginapi(this.state.email,this.state.pass);
           if(notified=="data")
           {
               window.location.reload();
           }
           else{
              this.setState({loader:false},()=>
              {
              console.log('first else');
             this.setState({loginerror:'Invalid login'});
              })
           }
        }
        catch(e)
        {
            console.log('first else');
            this.setState({loginerror:'Invalid login'});
        }
         }
        }
        getemail(e)
        {
           if(e.target.value=="")
           {
              this.setState({logemail:"Please enter email"},()=>
              {
              $('.lagemail').addClass('commonerror');
              })
           }
           else{
              this.setState({logemail:""},()=>
              {
              $('.logemail').removeClass('commonerror');
              })
           }
           this.setState({email:e.target.value});
        }
        getpass(e)
        {
           if(e.target.value=="")
           {
              this.setState({logpass:"Please enter password"},()=>
              {
              $('.logpass').addClass('commonerror');
              })
           }
           else{
              this.setState({logpass:""},()=>
              {
              $('.logpass').removeClass('commonerror');
              })
           }
            this.setState({pass:e.target.value});
        }
    

      render()
      {
          return(<div>
        <div className="loginbody"></div>
		<div className="grad"></div>
      <div className="mainlogin">
		<div className="header">
			<div className="bodyimage"><img src="/uc.png"/></div>
		</div>
	
		<div className="login">
				<input placeholder="Email address" type="email" name="email" value={this.state.email} onChange={this.getemail.bind(this)}  required=""/>
                   <span className="logemail">{this.state.logemail}</span><br/>
				<input type="password" value={this.state.pass} onChange={this.getpass.bind(this)} className="form-control" placeholder=" Password" required=""/>
                   <span className="logpass">{this.state.logpass}</span><br/>
				<button type="button" onClick={this.getauthdata.bind(this)} >{this.state.loginerror!=="" ? <span>Invalid login</span> : this.state.loader == true ? <span>Loading...</span> : <span>Login</span>}</button>
		</div>
      </div>
          </div>)
      }
      }


      export default Login;


