import React from "react";
import MaterialTable from "material-table";
import getMenus from './API/getMenus.js';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import recommended from './API/recommended.js';
import firebase from "firebase";
import firestore from "./API/firestore";
import axios from 'axios';
class Menus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menus: [], url: "", columns: [
                { title: 'Count', field: 'count' },
                { title: 'Dish Name', field: 'dish_name' },
                { title: 'Image', field: 'image' },
                { title: 'Price', field: 'price' },
                { title: 'Recommended', field: 'recommended' },
                { title: 'Category', field: 'category' },
                { title: 'Ingredients', field: 'ingredient' },
                { title: 'Minutes of preparation', field: 'peso' },
                { title: 'Enable/Disable', field: 'action' },
                { title: 'Edit', field: 'edit' },
                { title: 'Delete', field: 'delete' }
            ]
        }
    }
    componentDidMount = async () => {
        try {
            const ingredient = await getMenus();
            if (ingredient.list.length > 0) {
                var title = []
                var cot = 1;
                for (var i = 0; i < ingredient.list.length; i++) {
                    title[i] = {
                        count: ingredient.list[i].number ? ingredient.list[i].number : 0,
                        dish_name: ingredient.list[i].dish_name,
                        image: <img src={this.state.url + ingredient.list[i].featured_image} style={{ width: 40, borderRadius: '50%' }} />,
                        price: '€ ' + ingredient.list[i].price,
                        recommended: ingredient.list[i].recommended == "true" ? <input type="checkbox" name="vehicle2" id={ingredient.ids[i]} onClick={this.getreco.bind(this)} value={ingredient.list[i].recommended} checked /> : <input type="checkbox" name="vehicle2" id={ingredient.ids[i]} onClick={this.getreco.bind(this)} value={ingredient.list[i].recommended} />,
                        category: ingredient.list[i].category.map((p, x) => {
                            return (<span>{p.name + ',' + " "}</span>)
                        }),
                        ingredient: ingredient.list[i].Ingredient.map((m, x) => {
                            return (<span>{m + ',' + " "}</span>)
                        }),
                        peso: ingredient.list[i].Peso,
                        action: <button className={ingredient.list[i].status && ingredient.list[i].status == "block" ? 'btn btn-success' : 'btn btn-danger'} id={ingredient.ids[i]} onClick={this.changebutton.bind(this)}>{ingredient.list[i].status && ingredient.list[i].status == "block" ? 'Enable' : 'Disable'}</button>,
                        edit: <Link to={'editmenu/' + ingredient.ids[i]}><i class="fas fa-edit"></i></Link>,
                        delete: <Link id={ingredient.ids[i]} onClick={confirm("Sei sicuro di voler eliminare questo elemento?") ? this.removeItem.bind(this): ""}><i class="fas fa-trash-alt"></i></Link>,
                        key: ingredient.ids[i]
                    };
                }

                this.setState({ menus: title.sort((a, b) => (a.count > b.count) ? 1 : -1) });


            }
        }
        catch (e) {
            console.log('first else');
        }

    }
    changebutton(e) {
        if (e.target.className == "btn btn-danger") {

            var objectIndex = this.state.menus.findIndex((obj => obj.key == e.currentTarget.id));
            if (objectIndex !== -1) {
                this.state.menus[objectIndex].action = <button className="btn btn-success" id={e.currentTarget.id} onClick={this.changebutton.bind(this)}>Enable</button>;
                this.setState({ menus: this.state.menus });
            }
            firebase.firestore().collection('menus').doc(e.currentTarget.id).update({ status: "block" });
        }
        if (e.target.className == "btn btn-success") {
            var objectIndex = this.state.menus.findIndex((obj => obj.key == e.currentTarget.id));
            if (objectIndex !== -1) {
                this.state.menus[objectIndex].action = <button className="btn btn-danger" id={e.currentTarget.id} onClick={this.changebutton.bind(this)}>Disable</button>;
                this.setState({ menus: this.state.menus });
            }
            firebase.firestore().collection('menus').doc(e.currentTarget.id).update({ status: "active" });
        }
    }

    removeItem = async (e) => {
        firebase.firestore().collection('menus').doc(e.currentTarget.id).delete();
        var body = { dish_id: e.currentTarget.id };
        var datas = await axios.post('https://foodapi.ucooking.it/deletedish', body, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
        });
        this.componentDidMount();
    }
    getreco = async (e) => {
        if (e.target.value == "false") {
            var objectIndex = this.state.menus.findIndex((obj => obj.key == e.currentTarget.id));
            if (objectIndex !== -1) {
                this.state.menus[objectIndex].recommended = <input type="checkbox" name="vehicle2" id={e.currentTarget.id} onClick={this.getreco.bind(this)} value={"true"} checked />;
                this.setState({ menus: this.state.menus });
            }
            var val = "true"
            var data = await recommended(e.currentTarget.id, val);
        }
        else {
            var objectIndex = this.state.menus.findIndex((obj => obj.key == e.currentTarget.id));
            if (objectIndex !== -1) {
                this.state.menus[objectIndex].recommended = <input type="checkbox" name="vehicle2" id={e.currentTarget.id} onClick={this.getreco.bind(this)} value={"false"} />;
                this.setState({ menus: this.state.menus });
            }
            var val = "false"
            var data = await recommended(e.currentTarget.id, val);
        }

    }
    render() {
        return (
            <>
                <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                        columns={this.state.columns}
                        data={this.state.menus}
                        title="Menus"
                    />
                </div>

            </>
        );
    }
}
export default Menus;