/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Addmenu from "views/Addmenu.js";
import Menus from "views/Menus.js";
import Ingredient from "views/Ingredient.js";
import Ingredients from "views/Ingredients.js";
import UserProfile from "views/UserProfile.js";
import Orders from "views/Orders.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Editmenu from "views/editmenu.js";
import Notifications from "views/Notifications.js";
// import Upgrade from "views/Upgrade.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Monitor",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path:"/orders",
    name:"Lista Ordini",
    icon:"nc-icon nc-delivery-fast",
    component:Orders,
    layout:"/admin",
  },
  {
    path:"/ingredient",
    name:"Nuovo Ingrediente",
    icon:"nc-icon nc-simple-add",
    component:Ingredient,
    layout:"/admin",
  },
  {
    path:"/listingredients",
    name:"Elenco Ingredienti",
    icon:"nc-icon nc-bullet-list-67",
    component:Ingredients,
    layout:"/admin",
  },
  {
    path:"/addmenu",
    name:"Nuovo Elemento Menu",
    icon:"nc-icon nc-simple-add",
    component:Addmenu,
    layout:"/admin",
  },
  {
    path:"/menus",
    name:"Lista Elementi Menu",
    icon:"nc-icon nc-bullet-list-67",
    component:Menus,
    layout:"/admin",
  },
  {
    path: "/user",
    name: "Profilo Ristorante",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin",
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "nc-icon nc-circle-09",
  //   component: UserProfile,
  //   layout: "/admin",
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  // {
  //   // upgrade: true,
  //   path: "/editmenu",
  //   // name: "Upgrade to PRO",
  //   // icon: "nc-icon nc-alien-33",
  //   component: Editmenu,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
