import React from "react";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import ChartistGraph from "react-chartist";
import getIngredient from "./API/getIngredient";
import MaterialTable from "material-table";
import getOrders from "./API/getOrders";
import getMenus from './API/getMenus.js';
import updateStatus from './API/updateStatus.js';
import moment from 'moment';
class Dashboard extends  React.Component {
  constructor(props) {
     super(props);
     this.state={menusd:0,ingsd:0,ordersd:0,alltotal:[],orderCount:0,totalorders:[],columns:[
      { title: 'Dish Name', field: 'name' },
      { title: 'Image', field: 'image'},
      { title: 'Quantity', field: 'quantity' },
      { title: 'Accept', field: 'accept' },
      { title: 'Reject', field: 'reject' },
    ] }}

    accept= async(e)=>
    {
    var id =  e.currentTarget.dataset.div_name;
    var index=e.currentTarget.dataset.div_index;
    var status=e.currentTarget.id;
    var objectIndex=this.state.alltotal.findIndex((obj => obj.key == id));
    if(objectIndex!==-1)
    {
      var ohj=this.state.alltotal[objectIndex].items;
      ohj[index].confirm_by_restu="accept"
      var datav  = await updateStatus(ohj,id);
    }
    var secondObj=this.state.totalorders.findIndex((obj => obj.key == id));
    if(secondObj!== -1)
    {
           
          this.state.totalorders[secondObj].accept = <button type="button" class="btn btn-success"  data-div_name={id} data-div_index={index} id={status=="pending" ? "pending":"empty"}>Accepted</button>;
          this.setState({totalorders:this.state.totalorders});
    }
    this.componentDidMount();
  }
    reject= async(e)=>
    {
      var index=e.currentTarget.dataset.div_index
      var id=e.currentTarget.dataset.div_name;
      var status=e.currentTarget.id;
      var objectIndex=this.state.alltotal.findIndex((obj => obj.key == id));
      if(objectIndex!==-1)
      {
        var ohj=this.state.alltotal[objectIndex].items;
        ohj[index].confirm_by_restu="reject"
        var datav  = await updateStatus(ohj,id);
       
      }
    var secondObj=this.state.totalorders.findIndex((obj => obj.key == id));
    if(secondObj!== -1)
    {
           
           this.state.totalorders[secondObj].reject = <button type="button" class="btn btn-danger"  data-div_name={id} data-div_index={index} id={status=="pending" ? "pending":"empty"}>reject</button>;
          this.setState({totalorders:this.state.totalorders});
    }
    this.componentDidMount();
    }
    emty()
    {
      
    }
    componentDidMount= async ()=>{
      try 
      {
        const menus = await getMenus();
        const ings = await getIngredient();
        const orders = await getOrders();
        if(menus.ids.length>0)
        {
          var countm=0;
          for (let index = 0; index < menus.ids.length; index++) {
            countm++;
          }
          this.setState({menusd:countm});
        }
        if(ings.ids.length>0)
        {
          var counti=0;
          for (let index = 0; index < ings.ids.length; index++) {
            counti++;
          }
          this.setState({ingsd:counti});
        }
        if(orders.list.length>0)
        {
          var countc=0;
         
          var orderCount=0;
          var title1=[];
          for (let index = 0; index < orders.list.length; index++) {
            var title=[];
            orderCount++;
            countc+=Number(orders.list[index].grandtotal)-Number(orders.list[index].commition);
            for (let index2 = 0; index2 < orders.list[index].items.length; index2++) {
              if(localStorage.getItem("restid") == orders.list[index].items[index2].resturent_id)
              {
                let countxx=Number(orders.list[index].items[index2].actual_price)*Number(orders.list[index].items[index2].quantity);
                if(moment.unix(orders.list[index].current_date/1000).format("DD")==moment.unix(Date.now()/1000).format("DD"))
                {
                title[index,index2] = {
                  name:orders.list[index].items[index2].dish_name,
                  image:<img src={orders.list[index].items[index2].image} style={{ width: 40, borderRadius: '50%' }} />,
                  quantity:orders.list[index].items[index2].quantity,
                  key:orders.ids[index],
                  accept:orders.list[index].items[index2].confirm_by_restu!=="reject" ? <button type="button" class="btn btn-success" onClick={orders.list[index].items[index2].confirm_by_restu=="pending" ? this.accept.bind(this): this.emty.bind(this)} data-div_name={orders.ids[index]} data-div_index={index2} id={orders.list[index].items[index2].confirm_by_restu=="pending" ? orders.list[index].items[index2].confirm_by_restu:orders.list[index].items[index2].confirm_by_restu}>{orders.list[index].items[index2].confirm_by_restu=="pending" ? <span>Accept</span>:orders.list[index].items[index2].confirm_by_restu == "accept" ? <span>Accepted</span>:""}</button>:"",
                  reject:orders.list[index].items[index2].confirm_by_restu!=="accept" ? <button type="button" class="btn btn-danger"  onClick={orders.list[index].items[index2].confirm_by_restu=="pending" ? this.reject.bind(this): this.emty.bind(this)} data-div_name={orders.ids[index]} data-div_index={index2} id={orders.list[index].items[index2].confirm_by_restu=="pending" ? orders.list[index].items[index2].confirm_by_restu:"empty"}>{orders.list[index].items[index2].confirm_by_restu=="pending" ? <span>Reject</span>:orders.list[index].items[index2].confirm_by_restu == "reject" ? <span>Rejected</span>:""}</button>:""
                };
              }

              }
              
            }
          
           title1.push(title);
              }
          
              this.setState({ordersd:Number(countc).toFixed(1),totalorders:title1.flat(),orderCount:orderCount});
              var ohhh=[];
              for (let index3 = 0; index3 < orders.list.length; index3++) {
                 ohhh[index3]={
                  items:orders.list[index3].items,
                  key:orders.ids[index3],
                 }
                
              }
          this.setState({alltotal:ohhh});
          
        }
       
      }
      catch{

      }
    }
    gomenu()
    {
      this.props.history.push('/admin/menus');
    }
    goorder()
    {
      this.props.history.push('/admin/orders');
    }
    goings()
    {
      this.props.history.push('/admin/ingredients');
    }
  render()
  {
  return (
    <>
      <Container fluid>
        <Row>
        <Col xl="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Number of Ingredients</p>
                      <Card.Title as="h4">{this.state.ingsd}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats" onClick={this.goings.bind(this)}>
                  <i className="fas fa-redo mr-1"></i>
                  see this
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Revenue</p>
                      <Card.Title as="h4">$ {this.state.ordersd}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats" onClick={this.goorder.bind(this)}>
                  <i className="far fa-calendar-alt mr-1"></i>
                  see this
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Menus</p>
                      <Card.Title as="h4">{this.state.menusd}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats" onClick={this.gomenu.bind(this)}>
                  <i className="far fa-clock-o mr-1"></i>
                  see this
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Orders</p>
                      <Card.Title as="h4">{this.state.orderCount}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats" onClick={this.goorder.bind(this)} >
                  <i className="fas fa-redo mr-1"></i>
                  see this
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
        <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Today Orders</Card.Title>
                <p className="card-category">24 Hours performance</p>
              </Card.Header>
              <Card.Body>
              <div>
                {this.state.totalorders.length>0 ?
        <MaterialTable
          columns={this.state.columns}
          data={this.state.totalorders}
          title="Orders"
        />
            :""    }
      </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  {/* <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Click <i className="fas fa-circle text-warning"></i>
                  Click Second Time */}
                </div>
                <hr></hr>
                
              </Card.Footer> 
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Users Behavior</Card.Title>
                <p className="card-category">24 Hours performance</p>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartHours">
                  <ChartistGraph
                    data={{
                      labels: [
                        "9:00AM",
                        "12:00AM",
                        "3:00PM",
                        "6:00PM",
                        "9:00PM",
                        "12:00PM",
                        "3:00AM",
                        "6:00AM",
                      ],
                      series: [
                        [287, 385, 490, 492, 554, 586, 698, 695],
                        [67, 152, 143, 240, 287, 335, 435, 437],
                        [23, 113, 67, 108, 190, 239, 307, 308],
                      ],
                    }}
                    type="Line"
                    options={{
                      low: 0,
                      high: 800,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Click <i className="fas fa-circle text-warning"></i>
                  Click Second Time
                </div>
                <hr></hr>
                
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        
      </Container>
    </>
  );
}
}
export default Dashboard;
