import React from "react";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import $ from 'jquery';
import getList from './API/getList.js';
import { Multiselect } from 'multiselect-react-dropdown';
import getIngredient from './API/getIngredient.js';
import getcategory from './API/getcategory.js';
import getAlo from './API/getAlo.js';
import getMenus from './API/getMenus.js';
import Addmenudata from './API/Addmenudata.js';
import axios from 'axios';
const short = require('short-uuid');
// import { createStringLiteral } from "typescript";
class Addmenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ingredient: [], menus: [], category: [], getIdeal: [], Alogo: [], selectedAlo: [], selectedmenu: [], selectedCat: [], selectedIng: [],
            inputFields: [{ id: '', name: '', description: '' }],
            imageVideoUrl: "", postType: "", name: "", price: "", des: "", con: "", rin: "", peso: "", selectedValue: "", downloadURL: "", image: "", videolink: "", loading: false, number: ""
        }
    }
    componentDidMount = async () => {
        try {
            const ingredient = await getIngredient();
            this.setState({ ingredient: ingredient.data });
        }
        catch (e) {
            console.log('first else');
        }
        try {
            const category = await getcategory();
            var newcat = [];
            for (let index = 0; index < category.list.length; index++) {
                newcat.push({ 'name': category.list[index].name, 'id': category.ids[index] });
            }
            this.setState({ category: newcat });

        }
        catch (e) {
            console.log('first else');
        }
        try {
            const getIdeal = await getList();
            this.setState({ getIdeal: getIdeal });
        }
        catch (e) {
            console.log('idealidealidealideal');
        }
        try {
            const Alo = await getAlo();
            this.setState({ Alogo: Alo });
        }
        catch (e) {
            console.log('first else All');

        }

        try {
            const ingredient = await getMenus();

            if (ingredient.list.length > 0) {
                var title = []
                for (var i = 0; i < ingredient.list.length; i++) {
                    title[i] = {
                        name: ingredient.list[i].dish_name,
                        key: ingredient.ids[i]
                    };
                }
                this.setState({ menus: title });
            }


        }
        catch (e) {
            console.log('first else');
        }
    }
    onSelectAlo(selectedList, selectedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedAlo: output });
    }
    onRemoveAlo(selectedList, removedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedAlo: output });
    }

    onSelectmenu(selectedList, selectedItem) {
        this.setState({ selectedmenu: selectedList });
    }
    onRemovemenu(selectedList, removedItem) {
        this.setState({ selectedmenu: selectedList });
    }

    onSelectCat(selectedList, selectedItem) {
        this.setState({ selectedCat: selectedList });
    }
    onRemoveCat(selectedList, removedItem) {
        this.setState({ selectedCat: selectedList });
    }
    onSelectIng(selectedList, selectedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedIng: output });
    }
    onRemoveIng(selectedList, removedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedIng: output });
    }

    getname(e) {
        this.setState({ name: e.target.value });
    }
    getprice(e) {
        this.setState({ price: e.target.value });
    }
    fetchpeso(e) {
        this.setState({ peso: e.target.value });
    }
    fetchcon(e) {
        this.setState({ con: e.target.value });
    }
    fetchrin(e) {
        this.setState({ rin: e.target.value });
    }
    fetchdes(e) {
        this.setState({ des: e.target.value });
    }

    onImageVideoChange = e => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
            if (streetaddress == "data:image") {
                this.setState({
                    imageVideoUrl: reader.result
                });
            }
        };
    }


    submitdata = async () => {
        if (this.state.name == "" || this.state.price == "" || this.state.rin == "" || this.state.des
            == "" || this.state.con == "" || this.state.selectedCat == [] || this.state.selectedAlo == [] || this.state.peso == "" || this.state.selectedIng == []
            || this.state.imageVideoUrl == "") {
            alert('Impossibile salvare, si prega di compilare tutti i campi obbligatori!');
        } else if (this.state.imageVideoUrl == "") {
            alert('Inserire un immagine per procedere al salvataggio');
        }
        else {
            this.setState({ loading: true });
            var body = { image: this.state.imageVideoUrl };
            axios.post('https://foodapi.ucooking.it/imageurl_ucooking', body, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }).then((res) => {
                this.setState({ downloadURL: res.data.image }, () => {
                    if (this.state.downloadURL == "") {
                        alert('please Select image');
                        this.setState({ loading: false });
                    }
                    else {
                        this.storedata();
                    }
                });
            })
        }
    }
    number(e) {
        this.setState({ number: e.target.value });
    }
    storedata = async () => {
        var object = {
            'name': this.state.name,
            'price': this.state.price,
            'rin': this.state.rin,
            'image': this.state.downloadURL,
            'description': this.state.des,
            'con': this.state.con,
            'related': this.state.selectedmenu,
            'category': this.state.selectedCat,
            'alli': this.state.selectedAlo,
            'peso': this.state.peso,
            'ing': this.state.selectedIng,
            'videolink': this.state.videolink,
            'number': this.state.number,
            'cosa': this.state.inputFields
        }
        try {
            var datasubmited = await Addmenudata(object);
            if (datasubmited) {
                var bodys = {
                    'id': datasubmited, 'merchant_id': localStorage.getItem("restid"), 'name': this.state.name, 'price': this.state.price,
                    'currency': "EUR", 'category': this.state.selectedCat[0].id, 'bom': this.state.inputFields, 'videolink': this.state.videolink
                };
                console.log("bodys", bodys);
                await axios.post('https://foodapi.ucooking.it/insert_product', bodys, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
                var body = { dish_name: this.state.name, restu_id: localStorage.getItem("restid"), dish_id: datasubmited };
                await axios.post('https://foodapi.ucooking.it/addish', body, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }).then((res) => {
                    if (res.data.status == "success") {
                        alert("Nuovo Elemento aggiunto con Successo");
                        this.setState({ loading: false });
                        window.location.reload();
                    } else {
                        alert("Qualcosa non ha funzionato...");
                        this.setState({ loading: false });
                    }
                })

            }
        }
        catch (e) {

        }
    }
    videolinkfunction(e) {
        this.setState({ videolink: e.target.value });
    }
    handleInputChange(index, event) {
        var values = [...this.state.inputFields];
        if (event.target.name == "name") {
            values[index].name = event.target.value;
            values[index].id = short.generate();
        }
        if (event.target.name == "description") {
            values[index].description = event.target.value;
        }
        this.setState({ inputFields: values });
    };
    handleAddFields = () => {
        this.setState(prevState => ({
            inputFields: [...prevState.inputFields, { id: '', name: '', description: '' }]
        }));
    }
    handleRemoveFields(index) {
        var values = [...this.state.inputFields];
        values.splice(index, 1);
        this.setState({ inputFields: values });
    };
    created_ci() {

        return this.state.inputFields.map((inputField, index) => (


            <div class="customdiv">
                <Row>
                    <Col md="6">
                        <Form.Group>
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={inputField.name}
                                onChange={this.handleInputChange.bind(this, index)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group>
                            <label htmlFor="description">Descrizione</label>
                            <input
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                value={inputField.description}
                                onChange={this.handleInputChange.bind(this, index)}
                            />
                        </Form.Group>
                    </Col>


                    {this.state.inputFields.length > 1 ? <Button
                        className="btn-info.btn-fill"
                        type="button"
                        onClick={this.handleRemoveFields.bind(this, index)}
                    >
                        -
                    </Button> : ""}

                </Row>
            </div>


        ))
    }
    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card className="p-3">
                                <Card.Header>
                                    <Card.Title as="h4">Aggiungi un nuovo Elemento al menù</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label htmlFor="exampleInputEmail1">
                                                        Seleziona la categoria *
                                                    </label>
                                                    <Multiselect
                                                        options={this.state.category} // Options to display in the dropdown
                                                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectCat.bind(this)} // Function will trigger on select event
                                                        onRemove={this.onRemoveCat.bind(this)} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Nome Elemento *</label>
                                                    <Form.Control
                                                        onChange={this.getname.bind(this)}
                                                        placeholder="Nome Elemento"
                                                        type="text"
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Prezzo *</label>
                                                    <Form.Control
                                                        placeholder="Prezzo"
                                                        type="Number"
                                                        onChange={this.getprice.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Piatti correlati</label>
                                                    <Multiselect
                                                        options={this.state.menus} // Options to display in the dropdown
                                                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectmenu.bind(this)} // Function will trigger on select event
                                                        onRemove={this.onRemovemenu.bind(this)} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </Form.Group>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Seleziona gli Ingredienti *</label>
                                                    <Multiselect
                                                        options={this.state.ingredient} // Options to display in the dropdown
                                                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectIng.bind(this)} // Function will trigger on select event
                                                        onRemove={this.onRemoveIng.bind(this)} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Seleziona gli Allergeni *</label>
                                                    <Multiselect
                                                        options={this.state.Alogo} // Options to display in the dropdown
                                                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectAlo.bind(this)} // Function will trigger on select event
                                                        onRemove={this.onRemoveAlo.bind(this)} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                        singleSelect={false}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Minuti di preparazione *</label>
                                                    <Form.Control
                                                        placeholder="Minuti di preparazione"
                                                        type="text"
                                                        onChange={this.fetchpeso.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Youtube link</label>
                                                    <Form.Control
                                                        placeholder="Youtube link"
                                                        type="text"
                                                        onChange={this.videolinkfunction.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Modalità di rinvenimento *</label>
                                                    <Form.Control
                                                        placeholder="Modalità di rinvenimento"
                                                        type="text"
                                                        onChange={this.fetchrin.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>

                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Modalità di conservazione *</label>
                                                    <Form.Control
                                                        placeholder="Modalità di conservazione"
                                                        type="text"
                                                        onChange={this.fetchcon.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Descrizione *</label>
                                                    <Form.Control
                                                        cols="80"
                                                        placeholder="Descrizione"
                                                        rows="4"
                                                        as="textarea"
                                                        onChange={this.fetchdes.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Immagine *</label>
                                                    <Form.Control
                                                        type="file" id="file" onChange={this.onImageVideoChange.bind(this)} name="myfile"
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Ordinamento *</label>
                                                    <Form.Control
                                                        type="number" id="text" onChange={this.number.bind(this)} value={this.state.number} name="myfile"
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            {this.state.imageVideoUrl != "" ? <Col md="6">
                                                <Card className="">
                                                    <div className="">
                                                        <img
                                                            alt="..."
                                                            src={this.state.imageVideoUrl}
                                                        ></img>
                                                    </div>
                                                </Card>
                                            </Col> : ""}
                                        </Row>
                                        <Button
                                            className="btn-info.btn-fill"
                                            type="button"
                                            onClick={this.handleAddFields.bind(this)}
                                        >
                                            +
                                        </Button>
                                        {this.created_ci()}
                                        <Button
                                            className="btn-fill pull-right"
                                            type="button"
                                            variant="info"
                                            onClick={this.state.loading == false ? this.submitdata.bind(this) : ""}
                                        >
                                            {this.state.loading == true ? "Savataggio in corso....." : "Salva"}
                                        </Button>
                                        <div className="clearfix"> * Campo obbligatorio</div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </>
        );
    }
}
export default Addmenu;
