import firebase from "firebase";
import firestore from "./firestore";
export default async function recommended (id,value) {
               try {
                   console.log('enter',value,"    ",id);
                           var documentSnapshot = await firebase.firestore().collection('menus').doc(id).update({
                            recommended:value
                        });
                     
               return await documentSnapshot;
                   }
               catch {
           return 'error';
              }
}