import firebase from "firebase";
import firestore from "./firestore";
export default async function getOrders () {
          var response =  await firebase.firestore().collection("orders").where('restu_id', '==', localStorage.getItem('restid')).get();
            const data = await response.docs.map(doc => doc.data());
            const data2 = await response.docs.map(doc => doc.id);
            var obj={'list':data,'ids':data2};
               if(obj!=={})
               {
                   return await obj;
               }
               else
               {
                   return "error"
               }
                           
}

// .where('payment', '==', "success")

//ess :   GG3PX0CqN2bcE63vpVVhkxSoVaH3
// another : PP5uGlekHcfPlBFCpwXSMRWUeNE3