import firebase from "firebase";
import firestore from "./firestore";
export default async function Loginapi (email,password) {
    try{
    const authdb = firebase.auth();
    var login = await authdb.signInWithEmailAndPassword(email, password);
    var response2 =  await firebase.firestore().collection("restaurant").where('resturent_id', '==', login.user.uid).get();
    const data = await response2.docs.map(doc => doc.data());
    localStorage.setItem('phone',data[0].phone_no);
    localStorage.setItem('restid', login.user.uid);
    localStorage.setItem('resturent',"right");
    localStorage.setItem('name',data[0].name);
    localStorage.setItem('email',data[0].restaurant_email);
    return 'data';
}
catch(e){
return 'invalid';
}
}

