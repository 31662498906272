import firebase from "firebase";
import firestore from "./firestore";
import axios from "axios";
export default async function Addmenudata(object) {
  // try{
    const userRef = await firebase.firestore().collection('menus').add({
      dish_name: object.name,
      price:object.price,
      resturent_id:localStorage.getItem("restid"),
      resturent_name:localStorage.getItem("name"),
      rinvenimento:object.rin,
      featured_image:object.image,
      description:object.description,
      conservazione:object.con,
      category:object.category,
      allergeni:object.alli,
      Peso:object.peso,
      video:object.videolink,
      related:object.related,
      ratting:0,
      recommended:"false",
      status:"active",
      Ingredient:object.ing,
      number:object.number,
      cosa:object.cosa
    });
   var data = await userRef.id;
    return data;

  //}
  // catch(e){
  //   return "error"
  // }
}