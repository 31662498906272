import firebase from "firebase";
import firestore from "./firestore";
export default async function getMenus () {
          var response =  await firebase.firestore().collection("menus").where('resturent_id', '==', localStorage.getItem("restid")).get();
            const data = await response.docs.map(doc => doc.data());
            const data2 = await response.docs.map(doc => doc.id);
            var obj={'list':data,'ids':data2}
               if(data!='')
               {
                   return await obj;
               }
               else
               {
                   return "error"
               }
                           
}

