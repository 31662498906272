import React from "react";
import MaterialTable from "material-table";
import getMenus from './API/getIngredient.js';
class Ingredients extends  React.Component {
    constructor(props) {
       super(props);
       this.state={menus:[],columns:[
        { title: 'Nome Ingrediente', field: 'name' }
      ]}
      }
      componentDidMount = async ()=>{
        try 
        {
          const ingredient = await getMenus();
          if(ingredient.data.length>0)
          {
          var title=[]
          for (var i = 0; i < ingredient.data.length; i++) {
          title.push({
          name:ingredient.data[i].name,
          })
          }
          this.setState({ing:title.sort(function(a, b) {
            return a.name.localeCompare(b.name);
         })});
          }
        }
        catch(e)
        {
            console.log('first else');
        }
          
      }

 render() {
  return (
    <>
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={this.state.columns}
          data={this.state.ing}
          title="Elenco Ingredienti"
        />
      </div>
             
    </>
  );
}
}
export default Ingredients;