import React from "react";
import MaterialTable from "material-table";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import getOrders from './API/getOrders.js';
import moment from "moment";
class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menus: [], columns: [
                { title: 'Id Ordine', field: 'order' },
                { title: 'Data Ordine', field: 'orderdate' },
                { title: 'Data/Ora Consegna', field: 'order2' },
                { title: 'Cliente', field: 'name' },
                { title: 'Telefono', field: 'phone' },
                { title: 'Email', field: 'email' },
                { title: 'Indirizzo', field: 'address' },
                { title: 'Pagato', field: 'ammount' },
                { title: 'Commissioni', field: 'commition' },
                { title: 'Guadagno', field: 'earning' },
                { title: 'Dettaglio', field: 'see' },
            ]
        }
    }

    componentDidMount = async () => {
        try {
            const ingredient = await getOrders();
            if (ingredient.list.length > 0) {
                var title = [];
                for (let index = 0; index < ingredient.list.length; index++) {
                    // var title=[];
                    // for (let index2 = 0; index2 < ingredient.list[index].items.length; index2++) {
                    // if(localStorage.getItem("restid") == ingredient.list[index].items[index2].resturent_id)
                    // {
                    title.push({
                        order: ingredient.ids[index],



                        orderdate: moment(new Date(ingredient.list[index].current_date)).format('DD/MM/YYYY'),
                        order2: moment(new Date(ingredient.list[index].current_date)).format('DD/MM/YYYY'),


                        name: ingredient.list[index].user_name,
                        phone: ingredient.list[index].phone,
                        email: ingredient.list[index].email,
                        address: ingredient.list[index].street + " " + ingredient.list[index].city + " " + ingredient.list[index].state + " " + ingredient.list[index].country,
                        // dishName:ingredient.list[index].items[index2].dish_name,
                        // dishImage:<img src={ingredient.list[index].items[index2].image} style={{ width: 40, borderRadius: '50%' }} />,
                        // quantity:ingredient.list[index].items[index2].quantity,
                        ammount: ingredient.list[index].grandtotal,
                        commition: ingredient.list[index].commition,
                        earning: Number(ingredient.list[index].grandtotal) - Number(ingredient.list[index].commition),
                        see: <Link to={'dishlist/' + ingredient.ids[index]}><i class="far fa-eye"></i></Link>,












                        // address: <table class="table">
                        // <tr>
                        // <th>Street</th>
                        // <th>City</th>
                        // <th>State</th>
                        // <th>Zip code</th>
                        // </tr>
                        // <tr>
                        // <td>{ingredient.list[index].street}</td>
                        // <td>{ingredient.list[index].city}</td>
                        // <td>{ingredient.list[index].state}</td>
                        // <td>{ingredient.list[index].zip}</td>
                        // </tr>
                        // </table>,

                    });
                }
                //}
                // title1.push(title);
                //}
                this.setState({ menus: title.flat() });
            }
        }
        catch (e) {
            console.log('first else');
        }
    }
    render() {
        return (
            <>
                <div style={{ maxWidth: "100%" }}>
                    <MaterialTable
                        columns={this.state.columns}
                        data={this.state.menus}
                        title="Lista Ordini"
                        className="kapil"
                    />
                </div>

            </>
        );
    }
}
export default Orders;