import firebase from "firebase";
import firestore from "./firestore";
export default async function getcategory () {
          var response =  await firebase.firestore().collection("category").get();
            const data = await response.docs.map(doc => doc.data());
            const data2 = await response.docs.map(doc => doc.id);
            var obj={'list':data,'ids':data2};
               if(obj!=={})
               {
                   return await obj;
               }
               else
               {
                   return "error"
               }
                           
}