import React from "react";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "react-bootstrap";
import $ from 'jquery';
import getList from './API/getList.js';
import { Multiselect } from 'multiselect-react-dropdown';
import getIngredient from './API/getIngredient.js';
import getcategory from './API/getcategory.js';
import getAlo from './API/getAlo.js';
import getMenus from './API/getMenus.js';
import Updatemenudata from './API/Updatemenudata.js';
import fetchmenu from './API/fetchmenu.js';
import axios from 'axios';
const short = require('short-uuid');
class editmenu extends React.Component {
    constructor(props) {
        super(props);
        let UserName = null;
        const path = this.props.location.pathname;
        const params = this.props.location.pathname.split('/');
        UserName = params[params.length - 1];
        this.state = {
            dataid: UserName, ingredient: [], inputFields: [], menus: [], category: [], getIdeal: [], Alogo: [], selectedAlo: [], selectedAlo1: [], selectedmenu: [], selectedCat: [], selectedIdeal: [], selectedIng: [], selectedIdeal1: [], selectedIng1: [],
            imageVideoUrl: "", imageVideoUrl2: "", postType: "", url: "", name: "", price: "", des: "", con: "", rin: "", peso: "", selectedValue: "", downloadURL: "", image: "", videolink: "", number: ""
        }
    }
    componentDidMount = async () => {
        try {
            const ingredientss = await fetchmenu(this.state.dataid);
            var all = []
            for (var i = 0; i < ingredientss.allergeni.length; i++) {
                all[i] = {
                    name: ingredientss.allergeni[i],
                };
            }
            this.setState({ selectedAlo: all });
            // var idl=[]
            // for (var i = 0; i < ingredientss.ideal.length; i++) {
            // idl[i] = {
            // name:ingredientss.ideal[i],
            // };
            // }
            // this.setState({selectedIdeal:idl});
            var ing = []
            for (var i = 0; i < ingredientss.Ingredient.length; i++) {
                ing[i] = {
                    name: ingredientss.Ingredient[i],
                };
            }
            this.setState({ selectedIng: ing });
            this.setState({
                inputFields: ingredientss.cosa ? ingredientss.cosa : [], selectedAlo1: ingredientss.allergeni, selectedIng1: ingredientss.Ingredient, menusdata: ingredientss,
                name: ingredientss.dish_name, price: ingredientss.price, rin: ingredientss.rinvenimento, number: ingredientss.number ? ingredientss.number : "",
                downloadURL: ingredientss.featured_image, imageVideoUrl: ingredientss.featured_image, des: ingredientss.description, con: ingredientss.conservazione, selectedmenu: ingredientss.related,
                selectedCat: ingredientss.category, peso: ingredientss.Peso, videolink: ingredientss.video ? ingredientss.video : ""
            });
        }
        catch (e) {
            console.log('ids');
        }
        try {
            const ingredient = await getIngredient();
            this.setState({ ingredient: ingredient.data });
        }
        catch (e) {
            console.log('first else');
        }
        try {
            const category = await getcategory();
            var newcat = [];
            for (let index = 0; index < category.list.length; index++) {
                newcat.push({ 'name': category.list[index].name, 'id': category.ids[index] });
            }
            this.setState({ category: newcat });
        }
        catch (e) {
            console.log('first else');
        }
        try {
            const getIdeal = await getList();
            this.setState({ getIdeal: getIdeal });
        }
        catch (e) {
            console.log('idealidealidealideal');
        }
        try {
            const Alo = await getAlo();
            this.setState({ Alogo: Alo });
        }
        catch (e) {
            console.log('first else All');

        }
        try {
            const ingredient = await getMenus();
            if (ingredient.list.length > 0) {
                var title = []
                for (var i = 0; i < ingredient.list.length; i++) {
                    title[i] = {
                        name: ingredient.list[i].dish_name,
                        key: ingredient.ids[i]
                    };
                }
                this.setState({ menus: title });
            }
        }
        catch (e) {
            console.log('first else');
        }
    }
    onSelectAlo(selectedList, selectedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedAlo1: output });
    }
    onRemoveAlo(selectedList, removedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedAlo1: output });
    }

    onSelectmenu(selectedList, selectedItem) {
        this.setState({ selectedmenu: selectedList });
    }
    onRemovemenu(selectedList, removedItem) {
        this.setState({ selectedmenu: selectedList });
    }

    onSelectCat(selectedList, selectedItem) {
        this.setState({ selectedCat: selectedList });
    }
    onRemoveCat(selectedList, removedItem) {
        this.setState({ selectedCat: selectedList });
    }
    onSelectIng(selectedList, selectedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedIng1: output });
    }
    onRemoveIng(selectedList, removedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedIng1: output });
    }
    onSelectIdeal(selectedList, selectedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedIdeal1: output });

    }

    onRemoveIdeal(selectedList, removedItem) {
        var output = [];
        selectedList.forEach(function (value, key) {
            output.push(value.name);
        });
        this.setState({ selectedIdeal1: output });
    }
    getname(e) {
        this.setState({ name: e.target.value });
    }
    getprice(e) {
        this.setState({ price: e.target.value });
    }
    fetchpeso(e) {
        this.setState({ peso: e.target.value });
    }
    fetchcon(e) {
        this.setState({ con: e.target.value });
    }
    fetchrin(e) {
        this.setState({ rin: e.target.value });
    }
    fetchdes(e) {
        this.setState({ des: e.target.value });
    }

    onImageVideoChange = e => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
            if (streetaddress == "data:image") {
                this.setState({
                    imageVideoUrl2: reader.result
                });
                this.handleUpload(reader.result)
            }
        };
    }

    handleUpload = (data) => {
        var body = { image: data };
        axios.post('https://foodapi.ucooking.it/imageurl_ucooking', body, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            },
        })
            .then((res) => {
                console.log("res", res);
                this.setState({ downloadURL: res.data.image }, () => {
                    console.log("downloadURL", this.state.downloadURL);
                });
            })
        // console.log(this.state.image);
        //   let file = this.state.image;
        //   var storage = firebase.storage();
        //   var storageRef = storage.ref();
        //   var uploadTask = storageRef.child('folder/' + file.name).put(file);

        //   uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        //     (snapshot) =>{
        //       var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
        //       this.setState({progress})
        //     },(error) =>{
        //       throw error
        //     },() =>{
        //       // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{

        //       uploadTask.snapshot.ref.getDownloadURL().then((url) =>{
        //         this.setState({
        //           downloadURL: url
        //         }); 
        //       })
        //     document.getElementById("file").value = null

        //    }
        //  ) 
    }
    submitdata = async () => {

        if (this.state.name == "" || this.state.price == "" || this.state.rin == "" || this.state.selectedIdeal == [] || this.state.des
            == "" || this.state.con == "" || this.state.selectedCat == [] || this.state.selectedAlo == [] || this.state.peso == "" || this.state.selectedIng == []) {
            alert('please fill all details');
        }
        else {
            if (this.state.downloadURL == "") {
                alert('please Select image');
            }
            else {
                var object = {
                    'name': this.state.name,
                    'price': this.state.price,
                    'rin': this.state.rin,
                    // 'ideal':this.state.selectedIdeal1,
                    'featured_image': this.state.downloadURL,
                    'description': this.state.des,
                    'con': this.state.con,
                    'related': this.state.selectedmenu,
                    'category': this.state.selectedCat,
                    'alli': this.state.selectedAlo1,
                    'peso': this.state.peso,
                    'videolink': this.state.videolink,
                    'ing': this.state.selectedIng1,
                    'number': this.state.number,
                    'cosa': this.state.inputFields,
                    'resturent_name': localStorage.getItem("name")
                }

                try {
                    var datasubmited = await Updatemenudata(object, this.state.dataid);
                    if (datasubmited) {
                        var bodys = {
                            'id': this.state.dataid, 'merchant_id': localStorage.getItem("restid"), 'name': this.state.name, 'price': this.state.price,
                            'currency': "EUR", 'category': this.state.selectedCat[0].id, 'bom': this.state.inputFields, 'videolink': this.state.videolink
                        };
                        await axios.post('https://foodapi.ucooking.it/update_product', bodys, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem('token')
                            },
                        });
                        var body = {
                            dish_name: this.state.name, restu_id: localStorage.getItem("restid"), dish_id: this.state.dataid,
                            price: this.state.price,
                            rinvenimento: this.state.rin,
                            description: this.state.description,
                            conservazione: this.state.con,
                            category: [{ "name": this.state.selectedCat[0].name }],
                            allergeni: this.state.alli,
                            Peso: this.state.peso,
                            video: this.state.videolink,
                            related: this.state.related,
                            Ingredient: this.state.ing,
                            number: this.state.number,
                            cosa: this.state.cosa
                        };
                        await axios.post('https://foodapi.ucooking.it/updateish', body, {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: localStorage.getItem('token')
                            },
                        }).then((res) => {
                            if (res.data.status == "success") {
                                alert("Dish Update successfully");
                                window.location.reload();
                            } else {
                                alert("Somthing Went Wrong");
                            }
                        })
                    }
                    else {
                        alert("Somthing Went Wrong");
                    }
                }
                catch (e) {

                }
            }
        }
    }
    handleInputChange(index, event) {
        var values = [...this.state.inputFields];
        if (event.target.name == "name") {
            values[index].name = event.target.value;
            values[index].id = short.generate();
        }
        if (event.target.name == "description") {
            values[index].description = event.target.value;
        }
        this.setState({ inputFields: values });
    };
    handleAddFields = () => {
        this.setState(prevState => ({
            inputFields: [...prevState.inputFields, { id: '', name: '', description: '' }]
        }));
    }
    handleRemoveFields(index) {
        var values = [...this.state.inputFields];
        values.splice(index, 1);
        this.setState({ inputFields: values });
    };
    created_ci() {

        return this.state.inputFields.map((inputField, index) => (
            <div class="customdiv">
                <Row>
                    <Col md="6">
                        <Form.Group>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={inputField.name}
                                onChange={this.handleInputChange.bind(this, index)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                        <Form.Group>
                            <label htmlFor="description">Discription</label>
                            <input
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                value={inputField.description}
                                onChange={this.handleInputChange.bind(this, index)}
                            />
                        </Form.Group>
                    </Col>
                    {this.state.inputFields.length > 1 ? <Button
                        className="btn-info.btn-fill"
                        type="button"
                        onClick={this.handleRemoveFields.bind(this, index)}
                    >
                        -
                    </Button> : ""}
                </Row>
            </div>
        ))
    }
    number(e) {
        this.setState({ number: e.target.value });
    }
    videolinkfunction(e) {
        this.setState({ videolink: e.target.value });
    }
    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h4">Edit Dish</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div>
                                        <Row>
                                            <Col className="pl-1" md="6">
                                                <Form.Group>
                                                    <label htmlFor="exampleInputEmail1">
                                                        Select Categories
                                                    </label>
                                                    <Multiselect
                                                        options={this.state.category} // Options to display in the dropdown
                                                        selectedValues={this.state.selectedCat} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectCat.bind(this)} // Function will trigger on select event
                                                        onRemove={this.onRemoveCat.bind(this)} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className="pr-1" md="6">
                                                <Form.Group>
                                                    <label>Dish Name</label>
                                                    <Form.Control
                                                        onChange={this.getname.bind(this)}
                                                        value={this.state.name}
                                                        placeholder="Dishname"
                                                        type="text"
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="px-1" md="6">
                                                <Form.Group>
                                                    <label>Price</label>
                                                    <Form.Control
                                                        placeholder="price"
                                                        type="Number"
                                                        value={this.state.price}
                                                        onChange={this.getprice.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pl-1" md="6">
                                                <Form.Group>
                                                    <label>Select Related Dish</label>
                                                    <Multiselect
                                                        options={this.state.menus} // Options to display in the dropdown
                                                        selectedValues={this.state.selectedmenu} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectmenu.bind(this)} // Function will trigger on select event
                                                        onRemove={this.onRemovemenu.bind(this)} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md="6">
                                                <Form.Group>
                                                    <label>Select Ingredient</label>
                                                    <Multiselect
                                                        options={this.state.ingredient} // Options to display in the dropdown
                                                        selectedValues={this.state.selectedIng} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectIng.bind(this)} // Function will trigger on select event
                                                        onRemove={this.onRemoveIng.bind(this)} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className="pl-1" md="6">
                                                <Form.Group>
                                                    <label>Select Alolergeni</label>
                                                    <Multiselect
                                                        options={this.state.Alogo} // Options to display in the dropdown
                                                        selectedValues={this.state.selectedAlo} // Preselected value to persist in dropdown
                                                        onSelect={this.onSelectAlo.bind(this)} // Function will trigger on select event
                                                        onRemove={this.onRemoveAlo.bind(this)} // Function will trigger on remove event
                                                        displayValue="name"
                                                        singleSelect={false}
                                                    // Property name to display in the dropdown options
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col className="pl-1" md="6">
                                                <Form.Group>
                                                    <label>Minutes of preparation</label>
                                                    <Form.Control
                                                        placeholder="peso"
                                                        type="text"
                                                        value={this.state.peso}
                                                        onChange={this.fetchpeso.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>

                                            <Col className="pl-1" md="6">
                                                <Form.Group>
                                                    <label>Tutorial link</label>
                                                    <Form.Control
                                                        placeholder="Tutorial link"
                                                        type="text"
                                                        value={this.state.videolink}
                                                        onChange={this.videolinkfunction.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>

                                            <Col className="px-1" md="6">
                                                <Form.Group>
                                                    <label>Modalità di rinvenimento</label>
                                                    <Form.Control
                                                        placeholder="Modalità di rinvenimento"
                                                        type="text"
                                                        value={this.state.rin}
                                                        onChange={this.fetchrin.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pl-1" md="6">
                                                <Form.Group>
                                                    <label>Modalità di conservazione</label>
                                                    <Form.Control
                                                        placeholder="Modalità di conservazione"
                                                        type="text"
                                                        value={this.state.con}
                                                        onChange={this.fetchcon.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Description</label>
                                                    <Form.Control
                                                        cols="80"
                                                        placeholder="Description"
                                                        rows="4"
                                                        as="textarea"
                                                        value={this.state.des}
                                                        onChange={this.fetchdes.bind(this)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            {/* <Col className="pl-1" md="6">
                      <Form.Group>
                        <label>Select Ideal</label>
                        <Multiselect
options={this.state.getIdeal} // Options to display in the dropdown
selectedValues={this.state.selectedIdeal} // Preselected value to persist in dropdown
onSelect={this.onSelectIdeal.bind(this)} // Function will trigger on select event
onRemove={this.onRemoveIdeal.bind(this)} // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
/>
                      </Form.Group>
                    </Col> */}
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Order Number</label>
                                                    <Form.Control
                                                        type="number" id="test" onChange={this.number.bind(this)} value={this.state.number}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Dish Image</label>
                                                    <Form.Control
                                                        type="file" id="file" onChange={this.onImageVideoChange.bind(this)} name="myfile"
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {this.state.imageVideoUrl != "" ? <Col md="12">
                                                <Card className="">
                                                    <div className="">
                                                        <img
                                                            alt="..."
                                                            src={this.state.imageVideoUrl2 !== "" ? this.state.imageVideoUrl2 : this.state.imageVideoUrl.indexOf("https") == 0 ? this.state.imageVideoUrl : this.state.url + this.state.imageVideoUrl}
                                                        ></img>
                                                    </div>
                                                </Card>
                                            </Col> : ""}

                                        </Row>
                                        <Button
                                            className="btn-info.btn-fill"
                                            type="button"
                                            onClick={this.handleAddFields.bind(this)}
                                        >
                                            +
                                        </Button>
                                        {this.created_ci()}
                                        <Button
                                            className="btn-fill pull-right"
                                            type="button"
                                            variant="info"
                                            onClick={this.submitdata.bind(this)}
                                        >
                                            Update Menue
                                        </Button>
                                        <div className="clearfix"></div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </>
        );
    }
}
export default editmenu;
