import firebase from "firebase";
import firestore from "./firestore";
export default async function updateStatus (array,key) {
               try {
                   console.log('enter',array,"    ",key);
                           var documentSnapshot = await firebase.firestore().collection('orders').doc(key).update({
                            items:array
                        });
                     
               return await documentSnapshot;
                   }
               catch {
           return 'error';
              }
}