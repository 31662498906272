import React from "react";
import MaterialTable from "material-table";
import moment from 'moment';
import firebase from 'firebase';
class Dishlist extends  React.Component {
    constructor(props) {
       super(props);
       let UserName = null;
       const path = this.props.location.pathname;
       const params = this.props.location.pathname.split('/');
       UserName = params[params.length-1];
       this.state={orderid:UserName,menus:[],columns:[
        { title: 'Count', field: 'count'},
        // { title: 'Email', field: 'email'},
        { title: 'Dish Name', field: 'dishname' },
        { title: 'Image', field: 'image' },
        { title: 'Quantity', field: 'qunatity' },
        // { title: 'Actual Price', field: 'price' }, 
        // { title: 'Total Price', field: 'prices' },
      ] }
      }
  
componentDidMount = async ()=>{
try 
{
  var documentdocumentdocumentSnapshot = await firebase.firestore().collection('orders').doc(this.state.orderid).get();
var userData = await documentdocumentdocumentSnapshot.data();
               var ingredient = await userData;
             
if(ingredient.items.length>0)
{
var title=[];
var cont=1;
for (let index = 0; index < ingredient.items.length; index++) {
title.push({
count:cont++,
// email:ingredient.email,
dishname:ingredient.items[index].dish_name,
image:<img src={ingredient.items[index].image} style={{ width: 40, borderRadius: '50%' }}/>,
qunatity:ingredient.items[index].quantity,
// prices:ingredient.items[index].price,
// price:ingredient.items[index].actual_price
});
}

this.setState({menus:title.flat(),
  payment:ingredient.payment,username:ingredient.user_name,oid:'id',
  date:moment(new Date(ingredient.current_date)).format('DD/MM/YYYY'),city:ingredient.city,
  phone:ingredient.phone,email:ingredient.email,address:ingredient.street+" "+ingredient.city+" "+ingredient.state+" "+ingredient.country,
  grandtotal:ingredient.grandtotal,
  shipping:ingredient.shipping,commition:Number(ingredient.grandtotal)-Number(ingredient.commition),
  discount:ingredient.discount

  });

}
}
catch(e)
{
console.log('first else');
}
}   
 render() {
  return (
    <>
     <div className="detailpage">
          <div className="row">
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Order Id</h4>
              <p>{this.state.oid}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Data Ordine</h4>
              <p>{this.state.date}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Data/Ora Delivery</h4>
              <p>{this.state.date}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Customer Name</h4>
              <p>{this.state.username}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Customer Phone</h4>
              <p>{this.state.phone}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Customer Email</h4>
              <p>{this.state.email}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Customer Address</h4>
              <p>{this.state.address}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Grandtotal</h4>
              <p>{"€ "+Number(this.state.grandtotal).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Earning</h4>
              <p>{"€ "+Number(this.state.commition).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Discount</h4>
              <p>{"€ "+Number(this.state.discount).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Shipping</h4>
              <p>{"€ "+Number(this.state.shipping).toFixed(2).toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Payment Status</h4>
              <p>{this.state.payment}</p>
            </div>
            {/* <div className="col-sm-4 col-md-3 col-lg-2">
              <h4>Delivery Status</h4>
              <p>Pending</p>
            </div> */}
             
            
            
            </div>
        </div>
              <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={this.state.columns}
          data={this.state.menus}
          title="Dish list"
        />
      </div>
             
    </>
  );
}
}
export default Dishlist;