import React from "react";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import $ from 'jquery';
import AddIngi from './API/AddIngi.js';
class Ingredient extends  React.Component {
    constructor(props) {
       super(props);
       this.state={ingi:"" }
      }

      componentDidMount()
      {
        this.setState({ingi:""});
      }
      getingi(e)
      {
          this.setState({ingi:e.target.value});
      }
      submit= async ()=>{
      if(this.state.ingi!=="")
      {
        try 
        {
           const notified = await AddIngi(this.state.ingi);
           if(notified && notified!=="false")
           {
             alert('Ingrediente Aggiunto con Successo');
              window.location.reload();
           }
           else{
             alert('Qualcosa non ha funzionato... prego prova nuovamente');
           }
        
        }
        catch(e)
        {
            console.log('first else');
            
        }
    }
      }
render()
{
  return (
    
      <Container >
        <Row>
          <Col md="12">
            <Card>  
              <Card.Header>
                <Card.Title as="h4">Aggiungi un nuovo Ingrediente</Card.Title>
              </Card.Header>
              <Card.Body>
                <div >
                  <Row>
                    <Col className="" md="8">
                      <Form.Group>
                        <label>Nome Ingrediente</label>
                        <Form.Control
                        onChange={this.getingi.bind(this)}
                          placeholder="Nome Ingrediente"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  
                  </Row>
               
                 
                  <Button
                    className="btn-fill pull-right"
                    variant="info"
                    type="button"
                    onClick={this.submit.bind(this)}
                  >
                    Salva
                  </Button>
                  <div className="clearfix"></div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md="4">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={
                    require("assets/img/photo-1431578500526-4d9613015464.jpeg")
                      .default
                  }
                ></img>
              </div>
              <Card.Body>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/faces/face-3.jpg").default}
                    ></img>
                    <h5 className="title">Mike Andrew</h5>
                  </a>
                  <p className="description">michael24</p>
                </div>
                <p className="description text-center">
                  "Lamborghini Mercy <br></br>
                  Your chick she so thirsty <br></br>
                  I'm in that two seat Lambo"
                </p>
              </Card.Body>
              <hr></hr>
              <div className="button-container mr-auto ml-auto">
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-twitter"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-google-plus-square"></i>
                </Button>
              </div>
            </Card>
          </Col> */}
        </Row>
      </Container>
    
  );
}
}
export default Ingredient;
