import firebase from "firebase";
const config = {
    apiKey: "AIzaSyCeQUo0JgIK1PasJ0suFgVYyMfBbRDw_BQ",
    authDomain: "fooddelivery-cae0d.firebaseapp.com",
    databaseURL: "https://fooddelivery-cae0d-default-rtdb.firebaseio.com",
    projectId: "fooddelivery-cae0d",
    storageBucket: "fooddelivery-cae0d.appspot.com",
    messagingSenderId: "151658035738",
    appId: "1:151658035738:web:319a5a3b41a0ca61c3afa3"
};
firebase.initializeApp(config);
export default firebase;


// measurementId: "G-9N1W8V2XH0"