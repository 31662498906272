import firebase from "firebase";
import firestore from "./firestore";
export default async function getList() {
    var response =  await firebase.firestore().collection("ideal").get();
    const data = await response.docs.map(doc => doc.data());
       if(data!='')
       {
           return await data;
       }
       else
       {
           return "error"
       }
                           
}