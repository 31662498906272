import firebase from "firebase";
import firestore from "./firestore";
export default async function AddIngi(value) {
  try{
    var obj={name:value,id:localStorage.getItem('restid')};
    const userRef = await firebase.firestore().collection('ingredients').add(obj);
   var data = await userRef.id;
    return data;
  }
  catch(e){
    return "error"
  }
}